import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const BestillingMottattPage = () => {

    return (
        <Layout>
            <SEO title="Bestilling mottatt" />
            <div className="container">
                <div className="mx-auto text-center">
                    <p>Din bestilling er mottatt! Du vil bli kontaktet så fort som mulig.</p>
                    <p>Har du spørsmål kan du benytte <a href="mailto:kontakt@gijoe.no">kontakt@gijoe.no</a></p>
                </div>
            </div>
        </Layout>
    )
}

export default BestillingMottattPage